import Vue from "vue";
import App from "./App.vue";
import router from "../router";
import store from "../store";
import VeeValidate from "vee-validate";
import vueCountryRegionSelect from "vue-country-region-select";
import VueFormWizard from "vue-form-wizard";
import carousel from "vue-owl-carousel";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import axios from "axios";
import VueAxios from "vue-axios";
import NProgress from "nprogress";
import "nprogress/nprogress.css";

//Internal stuff created by me
import { dukaCore } from "../_helpers/duka-core.js";
import DukaModal from "../components/DukaModal.vue";
import DukaAlert from "../components/DukaAlert";
import DukaButton from "../components/DukaButton.vue";
Vue.prototype.$http = axios;
const token = localStorage.getItem("token");
if (token) {
  Vue.prototype.$http.defaults.headers.common["Authorization"] = token;
}

axios.interceptors.request.use(
  config => {
    NProgress.start();
    store.state.isLoading = true;
    return config;
  },
  error => {
    NProgress.done();
    store.state.isLoading = false;
    store.state.activeBtn = null;
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => {
    //NProgress.done();
    store.state.isLoading = false;
    store.state.activeBtn = null;
    return response;
  },
  error => {
    //NProgress.done();
    store.state.isLoading = false;
    store.state.activeBtn = null;
    return Promise.reject(error);
  }
);

const options = {
  latencyThreshold: 200,
  router: true,
  http: true
};

Vue.use(NProgress, options);
Vue.use(VueAxios, axios);
Vue.use(VueFormWizard);
Vue.use(VeeValidate);
Vue.use(vueCountryRegionSelect);
Vue.mixin(dukaCore);
Vue.component("duka-modal", DukaModal);
Vue.component("duka-alert", DukaAlert);
Vue.component("duka-button", DukaButton);
Vue.component("carousel", carousel);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
