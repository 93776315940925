<template>
  <div>
    <div
      class="l_b l_a"
      v-if="$store.state.accountDrawer"
      @click.self="$store.state.accountDrawer = !$store.state.accountDrawer"
    >
      <div class="l_c l_a"></div>
      <div class="y_a b_a l_e l_f b_f">
        <div id="vh-spark-menu">
          <div class="bm_i">
            <div class="b_a p_a p_ao p_v p_af bh_f b_g b_t">
              <a href="/" class="g_a q_a g_c j_a pl-1"
                ><span class="g_b"
                  ><img
                    aria-hidden="true"
                    class="mr-0 ml-2"
                    src="@/assets/logo-mini.png"/></span></a
              ><span id="hf-user-firstname" class="p_a p_y bm_j"></span
              ><button
                type="button"
                aria-expanded="true"
                aria-label="Close Navigation Menu"
                class="g_a g_c"
              >
                <span class="g_b"
                  ><img
                    aria-hidden="true"
                    src="@/assets/white-close.svg"
                    style="width: 24px; height: 24px;"
                    @click="
                      $store.state.accountDrawer = !$store.state.accountDrawer
                    "
                /></span>
              </button>
            </div>
          </div>
          <div tabindex="-1" class="p_a p_aq p_x p_ah p_az p_o p_f bm_a">
            <div class="p_a bm_h text-white">talar.rw</div>
            <div class="p_a p_o bm_b">
              <div class="b_a p_a p_az p_o p_f bm_c b_f">
                <!-- <router-link :to="{ name: 'home' }" class="j_a"
                  ><div class="b_a p_a p_ax p_m p_c b_g">
                    <img
                      aria-hidden="true"
                      src="@/assets/card.svg"
                      style="width: 24px; height: 24px;"
                    />
                    <div class="p_a p_ag"></div>
                    <span class="j_a j_c"
                      ><div class="x_a x_i">Services</div></span
                    >
                  </div></router-link
                > -->
                <template v-if="!isDukaLoggedIn">
                  <router-link :to="{ name: 'ordersearching' }" class="j_a"
                    ><div class="b_a p_a p_ax p_m p_c b_g">
                      <img
                        aria-hidden="true"
                        src="@/assets/track.svg"
                        style="width: 24px; height: 24px;"
                      />
                      <div class="p_a p_ag"></div>
                      <span class="j_a j_c"
                        ><div class="x_a x_i">Track Orders</div></span
                      >
                    </div></router-link
                  ><router-link :to="{ name: 'login' }" class="j_a"
                    ><div class="b_a p_a p_ax p_m p_c b_g">
                      <img
                        aria-hidden="true"
                        src="@/assets/account.svg"
                        style="width: 24px; height: 24px;"
                      />
                      <div class="p_a p_ag"></div>
                      <span class="j_a j_c"
                        ><div class="x_a x_i">Sign in</div></span
                      >
                    </div></router-link
                  ><router-link :to="{ name: 'signup' }" class="j_a"
                    ><div class="b_a p_a p_ax p_m p_c b_g">
                      <img
                        aria-hidden="true"
                        src="@/assets/register.svg"
                        style="width: 24px; height: 24px;"
                      />
                      <div class="p_a p_ag"></div>
                      <span class="j_a j_c"
                        ><div class="x_a x_i">Sign up</div></span
                      >
                    </div>
                  </router-link>
                </template>
                <template v-else>
                  <router-link :to="{ name: 'customerorders' }" class="j_a"
                    ><div class="b_a p_a p_ax p_m p_c b_g">
                      <img
                        aria-hidden="true"
                        src="@/assets/account.svg"
                        style="width: 24px; height: 24px;"
                      />
                      <div class="p_a p_ag"></div>
                      <span class="j_a j_c"
                        ><div class="x_a x_i">Your Account</div></span
                      >
                    </div></router-link
                  ><router-link :to="{ name: 'customerorders' }" class="j_a"
                    ><div class="b_a p_a p_ax p_m p_c b_g">
                      <img
                        aria-hidden="true"
                        src="@/assets/list.svg"
                        style="width: 24px; height: 24px;"
                      />
                      <div class="p_a p_ag"></div>
                      <span class="j_a j_c"
                        ><div class="x_a x_i">Orders List</div></span
                      >
                    </div></router-link
                  ><a
                    href="javascript:void(0)"
                    class="j_a"
                    @click.prevent="signout"
                    ><div class="b_a p_a p_ax p_m p_c b_g">
                      <i
                        class="dripicons-power text-danger"
                        style="width: 24px; height: 24px;"
                      ></i>
                      <div class="p_a p_ag"></div>
                      <span class="j_a j_c"
                        ><div class="x_a x_i">Logout</div></span
                      >
                    </div></a
                  >
                </template>
                <hr />
                <ul class="navbar-nav pl-1">
                  <li class="nav-item ">
                    <router-link class="nav-link" :to="{ name: 'shop' }">
                      Store
                    </router-link>
                  </li>
                  <li class="nav-item ">
                    <router-link class="nav-link" :to="{ name: 'aboutus' }">
                      About us
                    </router-link>
                  </li>
                  <li>
                    <router-link :to="{ name: 'contactus' }" class="nav-link"
                      >Contact Us</router-link
                    >
                  </li>
                  <li
                    class="nav-item"
                    v-for="(category, index) in categories"
                    :key="'category' + index"
                  >
                    <router-link
                      class="nav-link"
                      :to="{
                        name: 'shopsinglecategory',
                        params: { category_sug: category.category_slug }
                      }"
                    >
                      {{ category.category_name }}
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <duka-alert
      :modalTitle="alertDefaults.title"
      :modalContent="alertDefaults.content"
      :actionButton="alertDefaults.actionButton"
      @accept-alert="toggleActions(alertDefaults.actionType)"
      @close="closeAlert"
      :actionButtonClasses="alertDefaults.classes"
      v-if="alertDefaults.modalOpen"
    />

    <header class="header02">
      <div
        class="header-top py-2"
        style="background: #041e42!important;"
        v-if="!$parent.isMoboEnv"
      >
        <div class="container">
          <div class="row align-items-center">
            <div class="col-sm-12 text-right pr-2">
              <a href="tel:+250784081506" class="top-text" v-if="false">
                Call us: (+250) 784 081 506
              </a>
            </div>
          </div>
        </div>
      </div>

      <header
        class="navbar navbar-expand-lg navbar-light bg-light px-0 mobo-hidden"
      >
        <div class="container flex-sm-nowrap">
          <div
            class="app-btn-drawn border p-0 rounded mx-3"
            v-if="!$parent.isMoboEnv"
          >
            <button
              type="button"
              aria-expanded="false"
              aria-label="Menu"
              class="g_a ap_c g_c"
              @click="$store.state.accountDrawer = !$store.state.accountDrawer"
            >
              <span class="g_b"
                ><img
                  aria-hidden="true"
                  src="@/assets/menu.png"
                  style="width: 32px; height: 32px;"
              /></span>
            </button>
          </div>
          <router-link
            class="navbar-brand mr-0 mr-sm-4"
            style="max-width: 140px;"
            :to="{ name: 'shophome' }"
          >
            <img
              src="@/assets/logo-full.png"
              class="img img-fluid"
              alt="logo"
            />
          </router-link>
          <!-- navbar buttons-->
          <div
            class="navbar-btns d-flex position-relative order-sm-3 align-items-center"
          >
            <template v-if="isDukaLoggedIn">
              <div class="account-area">
                <div class="">
                  <i class="dripicons-user"></i>
                  {{ currentUser.firstname }} {{ currentUser.lastname }}
                  <i class="dripicons-chevron-down"></i>
                  <!-- <img
                    :src="dukaURL + 'assets/avatar.jpg'"
                    class="img img-thumbnail"
                    alt=""
                  /> -->
                </div>
                <ul class="user-meta-dropdown">
                  <!-- <li class="user-title" v-if="!isEmpty(currentUser)">
                    {{ currentUser.firstname }} {{ currentUser.lastname }}
                  </li>
                  <hr /> -->
                  <li>
                    <router-link :to="{ name: 'customerorders' }"
                      >My Account</router-link
                    >
                  </li>
                  <!-- <li>
                    <router-link :to="{ name: 'moneytransfer' }"
                      >Send Money</router-link
                    >
                  </li> -->
                  <li>
                    <router-link :to="{ name: 'customerorders' }"
                      >Orders List</router-link
                    >
                  </li>
                  <li>
                    <a href="javascript:void(0)" @click.prevent="signout"
                      >Logout</a
                    >
                  </li>
                </ul>
              </div>
            </template>

            <template v-else>
              <router-link
                class="navbar-btn bg-0 w-auto my-2 border-left-0"
                :to="{ name: 'ordersearching' }"
                ><span class="d-flex position-relative"
                  ><img
                    aria-hidden="true"
                    src="@/assets/track.svg"
                    style="width: 24px; height: 24px;margin-top: -2px;"
                  />Tracking</span
                ></router-link
              >
              <router-link
                class="navbar-btn w-auto bg-0 my-2"
                :to="{ name: 'login' }"
                ><span class="d-flex position-relative"
                  ><img
                    aria-hidden="true"
                    src="@/assets/account.svg"
                    style="width: 24px; height: 24px;margin-top: -2px;"
                  />Account</span
                ></router-link
              >
            </template>
            <a
              class="navbar-btn bg-0 my-3"
              :class="{ 'border-left-0': isDukaLoggedIn }"
              href="javascript:void(0)"
              @click.prevent="toggleCart"
            >
              <span class="d-block position-relative">
                <span class="navbar-btn-badge text-white">{{
                  cartData.items
                }}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="mx-auto"
                >
                  <circle cx="9" cy="21" r="1" />
                  <circle cx="20" cy="21" r="1" />
                  <path
                    d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"
                  />
                </svg>
              </span>
            </a>
          </div>
          <!-- search-box-->
          <div
            class="flex-grow-1 pb-0 pt-sm-0 my-0 px-sm-2 pr-lg-4 order-sm-2"
            v-if="!$parent.isMoboEnv"
          >
            <form action="/home-search" id="site-search">
              <div class="input-group">
                <input
                  class="form-control rounded-left"
                  type="text"
                  name="q"
                  placeholder="Search for a product"
                  aria-label="Search site"
                  aria-describedby="search-icon"
                  autocomplete="off"
                />

                <div class="input-group-append" @click="handleSearch">
                  <span class="input-group-text text-white" id="search-icon"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-search"
                    >
                      <circle cx="11" cy="11" r="8"></circle>
                      <line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg
                  ></span>
                </div>
              </div>
            </form>
          </div>
        </div>
      </header>

      <div class="header-main stuck">
        <div class="container d-flex align-items-center w-100">
          <template v-if="$parent.isMoboEnv">
            <button
              class="f_a al_c f_c mr-1"
              type="button"
              @click="$store.state.accountDrawer = !$store.state.accountDrawer"
              style="width:34px;height:34px;"
            >
              <span class="f_b">
                <img src="@/assets/menu.png" style="width:24px;height:24px;" />
              </span>
            </button>
            <div class="mx-auto">
              <img
                src="@/assets/logo-full.png"
                class="img img-fluid"
                style="width:120px;"
                alt="logo"
              />
            </div>
          </template>
          <nav class="primary-menu d-flex w-100" v-if="false">
            <ul>
              <li>
                <router-link :to="{ name: 'shophome' }">Home</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'shop' }">Store</router-link>
              </li>
              <li
                v-for="(category, index) in categories"
                :key="'category' + index"
              >
                <router-link
                  :to="{
                    name: 'shopsinglecategory',
                    params: { category_sug: category.category_slug }
                  }"
                >
                  {{ category.category_name }}
                </router-link>
              </li>
            </ul>
            <ul class="ml-auto">
              <li>
                <router-link
                  :to="{ name: 'contactus' }"
                  class="service btn btn-primary py-2 mt-1 text-white"
                  >Contact Us</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{ name: 'aboutus' }"
                  class="service btn btn-primary py-2 mt-1 text-white ml-2"
                  >About Us</router-link
                >
              </li>
            </ul>
          </nav>
          <div class="mobo-show ml-auto" v-if="$parent.isMoboEnv">
            <div class="navbar-btns">
              <a
                class="navbar-btn"
                href="javascript:void(0)"
                @click.prevent="toggleCart"
              >
                <span class="d-block position-relative">
                  <span class="mobo-badge text-white">{{
                    cartData.items
                  }}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="mx-auto"
                  >
                    <circle cx="9" cy="21" r="1" />
                    <circle cx="20" cy="21" r="1" />
                    <path
                      d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"
                    />
                  </svg>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-grow-1 py-1 my-0 px-4" v-if="$parent.isMoboEnv">
        <form action="/home-search" id="site-search-mobile">
          <div class="input-group">
            <input
              class="form-control rounded-left mobile-search"
              type="text"
              name="q"
              placeholder="Search for a product"
              aria-label="Search site"
              aria-describedby="search-icon"
              autocomplete="off"
            />
            <div class="input-group-append" @click="handleMobileSearch">
              <span class="input-group-text text-white mobile-search-btn"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-search"
                >
                  <circle cx="11" cy="11" r="8"></circle>
                  <line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg
              ></span>
            </div>
          </div>
        </form>
      </div>
    </header>

    <div
      class="offcanvas offcanvas-reverse"
      id="offcanvas-cart"
      style="display: block;"
      v-if="allItems.length"
    >
      <div
        class="offcanvas-header d-flex justify-content-between align-items-center"
      >
        <h3 class="offcanvas-title">Your cart</h3>
        <button class="close" type="button" @click="toggleCart">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="offcanvas-body">
        <div class="offcanvas-body-inner">
          <div class="widget widget-featured-entries pt-3">
            <div
              class="media"
              v-for="(product, index) in allItems"
              :key="'cartproducct' + index"
            >
              <div class="featured-entry-thumb mr-3">
                <a href="javascript:void(0)">
                  <img
                    :src="dukaURL + 'uploads/' + product.product_image"
                    :alt="product.product_title"
                    width="64"
                  />
                </a>
                <span
                  class="item-remove-btn"
                  @click="
                    selectRecord(product);
                    productDelete(choosenRow.product_title);
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-x"
                  >
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                  </svg>
                </span>
              </div>
              <div class="media-body">
                <h6 class="featured-entry-title">
                  <a href="javascript:void(0)">{{ product.product_title }}</a>
                </h6>
                <p class="featured-entry-meta">
                  {{ product.qty }}
                  <span class="text-muted">x</span>
                  RWF {{ addComma(product.price) }}
                </p>
              </div>
            </div>
            <hr />
            <div class="d-flex justify-content-between align-items-center py-3">
              <div class="font-size-sm">
                <span class="mr-2">Subtotal:</span>
                <span class="font-weight-semibold text-dark"
                  >RWF {{ addComma(subTotal) }}</span
                >
              </div>
            </div>
            <router-link
              class="btn btn-primary btn-sm btn-block"
              :to="{ name: 'shoppingcart' }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-credit-card mr-1"
              >
                <rect x="1" y="4" width="22" height="16" rx="2" ry="2" />
                <line x1="1" y1="10" x2="23" y2="10" /></svg
              >Checkout
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Header",
  data: () => ({
    allItems: [],
    subTotal: 0,
    categories: []
  }),
  created() {
    this.getLandindData();
    this.getCartItems();
    this.allItems = this.parseItems();
    this.calculateDiscount();
    if (this.isDukaLoggedIn) {
      this.loadCurrentUser();
    }
  },
  methods: {
    handleSearch() {
      document.getElementById("site-search").submit();
    },
    handleMobileSearch() {
      document.getElementById("site-search-mobile").submit();
    },
    getLandindData() {
      this.$store
        .dispatch("dukaGetRequest", "market/active-categories")
        .then(response => {
          this.categories = response.data.categories;
        });
    },
    removeCartItem() {
      this.$store.dispatch("cartRemove", this.choosenRow).then(() => {
        this.getTotalItems();
        this.allItems = this.parseItems();
      });
    },
    calculateDiscount(isLoanRequest = false) {
      let cartProducts = this.parseItems(isLoanRequest);
      let subTotal = 0;
      cartProducts.forEach(product => {
        subTotal += Number(this.getCartDiscount(product).replace(/,/g, ""));
      });
      if (isLoanRequest === true) this.loanSubTotal = subTotal;
      else this.subTotal = subTotal;
    },
    toggleActions(action) {
      switch (action) {
        case "product_delete":
          this.removeCartItem();
          break;
        case "loan_product_delete":
          this.removeLoanCartItem();
          break;
        default:
          console.log("default data");
          break;
      }
      this.alertDefaults.modalOpen = false;
    },
    productDelete(product_title) {
      this.alertDefaults = {
        title: "Removing cart item",
        content:
          "Are you sure you want to remove this product from shopping cart: <b>" +
          product_title +
          "</b>",
        actionType: "product_delete",
        actionButton: "Yes, remove",
        classes: "btn btn-danger",
        modalOpen: true
      };
    },
    toggleCart() {
      document.body.classList.toggle("offcanvas-open");
    }
  },
  mounted() {
    this.$root.$on("newCartItemsListner", arr => {
      this.cartData.items = arr.items;
      this.allItems = arr.productsArr;
    });
  }
};
</script>
<style lang="css"></style>
