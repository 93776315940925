<template>
  <div id="app">
    <Header />
    <router-view />
    <Footer />
    <Loader
      :spinnerType="'Bars'"
      :color="'#041e42'"
      :height="40"
      :width="40"
      v-if="$store.state.isLoading && $store.state.activeBtn === null"
    />
  </div>
</template>
<script>
import Footer from "./components/Footer.vue";
import Header from "./components/Header.vue";
import Loader from "../components/Loader.vue";
export default {
  name: "app",
  components: {
    Header,
    Footer,
    Loader
  },
  data: () => ({
    isMoboEnv: false
  }),
  created() {
    this.checkMobileEnv();
  },
  methods: {
    checkMobileEnv() {
      if (matchMedia) {
        const mq = window.matchMedia("(max-width: 768px)");
        mq.addListener(this.WidthChange);
        this.WidthChange(mq);
      }
    },
    WidthChange(mq) {
      this.isMoboEnv = mq.matches;
    }
  }
};
</script>
<style lang="css">
@media screen and (max-width: 768px) {
  .taller-wrapper {
    padding-top: 6rem;
  }
}
</style>
