<template>
  <footer
    class="footer bg-dark"
    :style="{
      'background-image': 'url(' + dukaURL + 'assets/images/footer-bg.png'
    }"
  >
    <div class="text-white" v-html="content"></div>

    <div class="foo-btm">
      <div class="container">
        <div class="row pt-4">
          <div class="col-sm-6 text-center text-sm-left">
            <ul class="list-inline font-size-sm">
              <li class="list-inline-item mr-3">
                <a
                  class="nav-link-inline nav-link-light"
                  href="javascript:void(0)"
                  >About shop</a
                >
              </li>
              <li class="list-inline-item mr-3">
                <a
                  class="nav-link-inline nav-link-light"
                  href="javascript:void(0)"
                  >Our Store</a
                >
              </li>
            </ul>
          </div>
          <div class="col-sm-6 text-center text-sm-right">
            <div class="d-flex" style="justify-content: flex-end;">
              <img
                style="width:120px; height: 40px"
                :src="dukaURL + 'assets/images/credit-cards.png'"
                alt="Credit cards"
              />
              <img
                style="width: 80px;margin-top: -9px;margin-left: 4px;"
                :src="dukaURL + 'assets/images/momo.png'"
                alt="Mobile Money"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="foo-navigation">
              <ul>
                <li>
                  <a href="javascript:void(0)">Terms and Conditions</a>
                </li>
                <li>
                  <a href="javascript:void(0)">Privacy &amp; Policy</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-6">
            <div class="copyright">
              Copyright ©
              <a href="javascript:void(0)">Boyoro</a>
              - {{ new Date().getFullYear() }} | Powered by
              <a href="javascript:void(0)">publinet.rw</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "ShopFooter",
  created() {
    this.loadContent("footer-text");
  }
};
</script>
<style lang="css">
/**
 * Footer
 */
.footer {
  background-size: auto;
  background-position: center top;
  background-repeat: no-repeat;
  background-color: #041e42;
  position: relative;
}

.footer .foo-top {
  padding: 100px 0 90px;
}

.footer .widget h5 {
  color: #fff;
  font-size: 20px;
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 25px;
}

.footer .widget h5:before {
  content: "";
  position: absolute;
  top: auto;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  height: 2px;
  width: 30px;
}

@media screen and (max-width: 575px) {
  .footer .widget h5 i {
    font-size: 14px;
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    text-align: center;
  }
}

@media screen and (max-width: 575px) {
  .footer .widget {
    margin-top: 30px;
  }
}

@media screen and (max-width: 991px) {
  .footer .foo-nav {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 575px) {
  .footer .foo-nav {
    margin-bottom: 0;
  }
  .footer .foo-nav ul {
    display: none;
  }
  .footer .foo-nav h5 {
    cursor: pointer;
    margin-bottom: 0;
  }
}

.footer .foo-nav li a {
  color: #fff;
  display: block;
  font-size: 15px;
  padding: 5px 0;
  padding-left: 10px;
  position: relative;
}

.footer .foo-nav li a:before {
  content: "\f0da";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  left: 0;
  top: 5px;
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.footer .foo-nav li a:hover {
  padding-left: 15px;
}

.footer .foo-address address {
  color: #fff;
  margin-bottom: 35px;
}

.footer .foo-address address a {
  color: #fff;
}

.footer .foo-btm {
  background-color: rgba(0, 0, 0, 0.1);
  padding: 25px;
}

.footer .foo-navigation ul li {
  display: inline-block;
}

.footer .foo-navigation ul li a {
  color: #fff;
  display: block;
  font-size: 15px;
  padding-right: 20px;
}

@media screen and (max-width: 991px) {
  .footer .foo-navigation ul li a {
    font-size: 12px;
    padding-right: 12px;
  }
}

.footer .foo-navigation ul li:last-child a {
  padding-right: 0;
}

@media screen and (max-width: 767px) {
  .footer .foo-navigation {
    text-align: center;
  }
}

.footer .copyright {
  color: #fff;
  font-size: 15px;
  text-align: center;
}

.footer .copyright a {
  color: #fff;
  display: inline-block;
  font-weight: 500;
}

@media screen and (min-width: 768px) {
  .footer .copyright {
    text-align: right;
  }
}

@media screen and (max-width: 991px) {
  .footer .copyright {
    font-size: 12px;
  }
}
.sb-light {
  border-color: rgba(255, 255, 255, 0.14);
  background-color: transparent;
  color: rgba(255, 255, 255, 0.9);
}
.social-btn {
  display: inline-block;
  width: 2.25rem;
  height: 2.25rem;
  -webkit-transition: border-color 0.25s ease-in-out,
    background-color 0.25s ease-in-out, color 0.25s ease-in-out;
  transition: border-color 0.25s ease-in-out, background-color 0.25s ease-in-out,
    color 0.25s ease-in-out;
  border: 1px solid #e7e7e7;
  border-radius: 50%;
  background-color: #fff;
  color: #545454;
  text-align: center;
  text-decoration: none;
  line-height: 2.125rem;
  vertical-align: middle;
}
.icon-box-icon {
  display: inline-block;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 4.5rem;
  height: 4.5rem;
  margin-bottom: 1.5rem;
  background-color: rgba(92, 119, 252, 0.1);
  border-radius: 50%;
  color: #041e42;
  text-align: center;
  line-height: 4.25rem;
}
.icon-box-title {
  margin-bottom: 0.5rem;
  font-size: 1rem;
}
</style>
